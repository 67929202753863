/*
 * @Descripttion: 权限 pinia
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 10:49:18
 * @LastEditors: icon
 * @LastEditTime: 2022-07-22 10:24:54
 */

import { PermissionState } from '@/types'
import { RouteRecordRaw } from 'vue-router'
import { defineStore } from 'pinia'
import { constantRoutes } from '@/router'
import { getUserRoute, getAliToken } from '@/api/basics'
import { UserRouteItem, UploadFile } from '@/types'
import { sessionStorage } from '@/utils/storage'
const modules = import.meta.glob('../../views/**/**.vue')
export const Layout = () => import('@/layout/index.vue')

export const filterAsyncRoutes = (routes: UserRouteItem[]) => {
  const res: RouteRecordRaw[] = []
  routes.forEach((route) => {
    const tmp = { ...route } as any
    const url = tmp.children.length ? `${tmp.url}/${tmp.children[0].url}` : ''
    const componentName = tmp.component
    if (tmp.component == 'Layout') {
      tmp.component = Layout
    } else {
      const component = modules[`../../views/${tmp.component}.vue`] as any
      if (component) {
        tmp.component = modules[`../../views/${tmp.component}.vue`]
      } else {
        tmp.component = modules[`../../views/error-page/404.vue`]
      }
    }
    if (tmp.children.length > 0) {
      tmp.children = filterAsyncRoutes(tmp.children)
    }
    const routeItem: any = {
      name: tmp.url.indexOf('/') == 0 ? '' : componentName,
      path: tmp.url,
      component: tmp.component,
      meta: {
        title: tmp.name,
        icon: tmp.icon,
        keepAlive: tmp.isHide ? false : tmp.type == 0 ? true : false,
        hidden: tmp.isHide,
        alwaysShow: false,
      },
      children: tmp.children,
    }
    if (tmp.type == 1 && tmp.children.length > 0) {
      routeItem.redirect = `${url}`
    }
    res.push(routeItem)
  })
  return res
}

const usePermissionStore = defineStore({
  id: 'permission',
  state: (): PermissionState => ({
    routes: [],
    addRoutes: [],
  }),
  actions: {
    setRoutes(routes: RouteRecordRaw[]) {
      this.addRoutes = routes
      // 静态路由 + 动态路由
      this.routes = constantRoutes.concat(routes)
    },
    generateRoutes() {
      return new Promise((resolve, reject) => {
        // API 获取动态路由
        getUserRoute()
          .then((response) => {
            const asyncRoutes = response.data
            const accessedRoutes = filterAsyncRoutes(asyncRoutes)
            this.setRoutes(accessedRoutes)
            resolve(accessedRoutes)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 ali oss token
    getAliTokenFn() {
      return new Promise<UploadFile>((resolve, reject) => {
        const aliToken = sessionStorage.get('aliToken') || ''
        if (!aliToken || aliToken.expires + 1800000 < Date.now()) {
          getAliToken()
            .then((response) => {
              const data = {
                data: response.data,
                expires: Date.now(),
              }
              sessionStorage.set('aliToken', data)
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(aliToken.data)
        }
      })
    },
  },
})

export default usePermissionStore
