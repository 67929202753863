/*
 * @Descripttion: 配置文件
 * @version:
 * @Author: icon
 * @Date: 2022-06-22 16:34:20
 * @LastEditors: qingzhuo
 * @LastEditTime: 2023-06-05 15:57:25
 */
// 开发地址
// const testUrl = 'https://api.fanyu.cn' // 线上环境
// const testUrl = 'https://tapi.fanyu.cn' // 测试环境
// const testUrl = 'http://192.168.0.17:31900' // dev
const testUrl = 'http://192.168.0.129:9900' // 兵=乒+乓
// const testUrl = 'http://192.168.0.140:9900' // 志发

// 接口地址
export const apiUrl = testUrl

// cos上传地址
export const cosUrl = 'https://cdn.fanyu.cn'

// 项目id
export const appChannelId = 5
// 0= "梵羽云（APP）
// 1= "梵羽云（微信小程序）
// 2= "梵羽精英（微信小程序）
// 3="梵羽云（抖音小程序）
// 4= "梵羽云（H5）
// 5 = "梵羽云（PC）
// 6= 学院培训

// 当前版本
export const version = '1.5.54'
