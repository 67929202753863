/*
 * @Descripttion: 主入口
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 09:25:38
 * @LastEditors: houqingzhuo h_q_z_986966@163.com
 * @LastEditTime: 2022-08-15 09:45:54
 */

import { createApp } from 'vue'
import App from './App.vue'

// 引入 elementplus 组件
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
// import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import Pagination from '@/components/Pagination/index.vue'
import 'dayjs/locale/zh-cn'

// 自定义样式
import '@/styles/index.scss'

// 路由鉴权
import '@/permission'

// 根据字典编码获取字典列表全局方法
// import { selectMetaContent } from '@/api/basics/meta'

// 引入svg注册脚本
import 'virtual:svg-icons-register'

// 引入 pinia 状态管理
import { createPinia } from 'pinia'

// 引入 router 路由
import router from '@/router'
const app = createApp(App)

// 全局方法
// app.config.globalProperties.$selectMetaContent = selectMetaContent

app
  .component('Pagination', Pagination)
  // @ts-ignore
  .use(router)
  // @ts-ignore
  .use(createPinia())
  .use(ElementPlus, { locale })
  .mount('#app')
