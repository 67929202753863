/*
 * @Descripttion: 路由
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 10:45:45
 * @LastEditors: icon
 * @LastEditTime: 2022-07-20 20:51:09
 */

// createWebHashHistory
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import useStore from '@/store'

export const Layout = () => import('@/layout/index.vue')

// 静态路由
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: { hidden: true },
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404.vue'),
    meta: { hidden: true },
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401.vue'),
    meta: { hidden: true },
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index.vue'),
        name: 'home',
        meta: { title: '首页', icon: 'homepage', affix: true },
      },
    ],
  },

  {
    path: '/:W+',
    component: Layout,
    redirect: '/404',
    meta: { hidden: true },
  },
  // {
  //   path: '/system',
  //   component: Layout,
  //   redirect: '/system/user',
  //   meta: {
  //     title: '系统管理',
  //     roles: ['ADMIN', 'GUEST'],
  //     keepAlive: true,
  //     icon: 'system',
  //     hidden: false,
  //     alwaysShow: null,
  //   },
  //   children: [
  //     {
  //       path: 'user',
  //       component: () => import('@/views/system/user/index.vue'),
  //       name: 'User',
  //       meta: {
  //         title: '用户管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'user',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/system/role/index.vue'),
  //       name: 'Role',
  //       meta: {
  //         title: '角色管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'role',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/system/menu/index.vue'),
  //       name: 'Cmenu',
  //       meta: {
  //         title: '菜单管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'menu',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //     {
  //       path: 'dept',
  //       component: () => import('@/views/system/dept/index.vue'),
  //       name: 'Dept',
  //       meta: {
  //         title: '部门管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'tree',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //     {
  //       path: 'dict',
  //       component: () => import('@/views/system/dept/index.vue'),
  //       name: 'Dict',
  //       meta: {
  //         title: '字典管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'education',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //     {
  //       path: 'client',
  //       component: () => import('@/views/system/dept/index.vue'),
  //       name: 'Client',
  //       meta: {
  //         title: '客户端管理',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'client',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/sms',
  //   component: Layout,
  //   redirect: '/sms/advert',
  //   meta: {
  //     title: '营销管理',
  //     roles: ['ADMIN', 'GUEST'],
  //     keepAlive: true,
  //     icon: 'number',
  //     hidden: false,
  //     alwaysShow: null,
  //   },
  //   children: [
  //     {
  //       path: 'advert',
  //       component: () => import('@/views/sms/advert/index.vue'),
  //       name: 'Advert',
  //       meta: {
  //         title: '广告列表',
  //         roles: ['ADMIN', 'GUEST'],
  //         keepAlive: true,
  //         icon: 'advert',
  //         hidden: false,
  //         alwaysShow: null,
  //       },
  //     },
  //   ],
  // },
]

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes as RouteRecordRaw[],
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

// 重置路由
export function resetRouter() {
  const { permission } = useStore()
  permission.routes.forEach((route) => {
    const name = route.name
    if (name) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export default router
