/*
 * @Author: icon
 * @Date: 2022-06-16 11:27:17
 * @LastEditors: houqingzhuo h_q_z_986966@163.com
 * @LastEditTime: 2022-09-28 14:39:20
 * @FilePath: /fanyu-web-pc-manage/src/api/basics/user.d.ts
 * @Description: 用户 api
 */
import http from '../http'
import httpUp from '../httpUp'
import { AxiosPromise } from 'axios'
import { MODULE_ROUTE } from './index'
import {
  IdParam,
  UserMeInfo,
  UserRouteItem,
  UserPageQueryParam,
  UserInfoItem,
  UserPageResult,
  UserFormData,
  updatedUser,
  UploadFile
} from '@/types'

// 获取当前用户信息
export function getUserMe(): AxiosPromise<UserMeInfo> {
  return http({
    url: `${MODULE_ROUTE}/sys/user/me`,
    method: 'get',
  })
}

// 获取当前用户(Route)列表
export function getUserRoute(): AxiosPromise<UserRouteItem[]> {
  return http({
    url: `${MODULE_ROUTE}/sys/user/route`,
    method: 'get',
  })
}

// 删除用户
export function deleteUser(params: IdParam) {
  return http({
    url: `${MODULE_ROUTE}/sys/user/delete`,
    method: 'post',
    params,
  })
}

// 批量删除用户
export function batchDeleteUser(data: any) {
  return http({
    url: `${MODULE_ROUTE}/sys/user/batchRemove`,
    method: 'post',
    data,
  })
}
// 获取用户详情
export function getUserDetail(params: IdParam): AxiosPromise<UserInfoItem> {
  return http({
    url: `${MODULE_ROUTE}/sys/user/userDetail`,
    method: 'get',
    params,
  })
}

// 用户分页列表
export function getUserListPage(data: UserPageQueryParam): AxiosPromise<UserPageResult> {
  return http({
    url: `${MODULE_ROUTE}/sys/user/page`,
    method: 'post',
    data,
  })
}

// 新增or修改用户
export function saveUser(data: UserFormData): AxiosPromise<UserInfoItem> {
  return http({
    url: `${MODULE_ROUTE}/sys/user/saveQyWxUser`,
    method: 'post',
    data,
  })
}
// 修改用户状态
export function updatedUser(params: updatedUser) {
  return http({
    url: `${MODULE_ROUTE}/sys/user/updateStatus`,
    method: 'get',
    params,
  })
}

// 获取销售人员
export function getSalesmanList(data: any) {
  return http({
    url: `${MODULE_ROUTE}/sys/dept/getDeptUserTable`,
    method: 'post',
    data,
  })
}

// excel导入人员并同步到企业微信
export function excelSyncWxUser(data: any): AxiosPromise<any> {
  return httpUp({
    url: `${MODULE_ROUTE}/sys/user/excelSyncWxUser`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
  })
}
